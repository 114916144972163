@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "Konnect Bold";
  src: url("/public/fonts/KonnectBold.otf");
}

@font-face {
  font-family: "Konnect Medium";
  src: url("/public/fonts/KonnectMedium.otf");
}

@font-face {
  font-family: "Konnect Regular";
  src: url("/public/fonts/KonnectRegular.otf");
}

@font-face {
  font-family: "Konnect Semibold";
  src: url("/public/fonts/KonnectSemiBold.otf");
}

@font-face{
  font-family: "Konnect Light";
  src: url("/public/fonts/KonnectLight.otf")
}

@layer base {
  h1{
    font-family: "Konnect Semibold";
    font-size: 28px;
  }
  
  h2{
    font-family: "Konnect Semibold";
    font-size: 24px;
  }
  
  h3{
    font-family: "Konnect Medium";
    font-size: 20px;
  }
  
  h4{
    font-family: "Konnect Medium";
    font-size: 18px;
  }


  p, td, span, thead,a,label{
    font-family: "Konnect Regular";
  }

  *::-webkit-scrollbar {
    width: 0;
  }
}

@layer components {
  .font-regular {
    font-family: "Konnect Regular";
  }
  
  .font-medium {
    font-family: "Konnect Medium";
  }
  
  .font-semibold {
    font-family: "Konnect SemiBold";
  }

  .font-light{
    font-family: "Konnect Light";
  }

  .headline-h1 {
    @apply text-[64px] leading-[92px];
  }
  .headline-h2 {
    @apply text-[48px] leading-[72px];
  }
  .headline-h3 {
    @apply text-[40px] leading-[60px];
  }
  .headline-h4 {
    @apply text-[32px] leading-[48px];
  }
  .headline-h5 {
    @apply text-[24px] leading-[32px];
  }
  .subheading {
    @apply text-[18px] leading-[24px];
  }
  .body-text {
    @apply text-[16px] leading-[20px];
  }
  .subtitle {
    @apply text-[14px] leading-[20px];
  }
  .caption {
    @apply text-[12px] leading-[20px];
  }
  .btn-primary {
    @apply bg-primary px-[30px] text-white rounded-md py-[14px] text-base font-medium leading-[24px];
  }
  .btn-outline-primary {
    @apply border border-primary px-[30px] text-primary rounded-md py-[14px] text-base font-medium leading-[24px];
  }
  .btn-primary-sm {
    @apply bg-primary text-white rounded-md w-fit text-sm font-medium px-[20px] py-1;
  }
  .btn-secondary {
    @apply py-[14px] px-[30px] rounded-md  text-base font-medium leading-[24px]  bg-white text-primary border border-primary;
  }

  .label {
    @apply block mb-1 text-[14px] text-neutral-800;
  }

  .input {
    @apply block w-full p-[14px] rounded-md border text-[14px] text-neutral-600 outline-none border-neutral-400 focus:border-primary;
  }

}

